import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'modal-info',
  template: `
    <span class="info-modal__label {{ labelClasses }}">{{ label }}</span>
    <div class="info-modal__group inline-block">
      <ng-content></ng-content>
    </div>
  `,
  standalone: true,
})
export class ModalInfoComponent {
  @Input()
  public label: string;

  @HostBinding('class.info-modal__element')
  public infoModalElement = true;

  @Input()
  public labelClasses: string;
}
